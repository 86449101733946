import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';

@Component({
  selector: 'app-list-renderer-widget-progress-icon',
  templateUrl: './list-renderer-widget-progress-icon.component.html',
  styleUrls: ['./list-renderer-widget-progress-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListRendererWidgetProgressIconComponent extends ListRendererWidgetBase implements OnInit {
  public checkmark_icon: string;
  public xmark_icon: string;
  public loading_icon: string;
  public parking_icon: string;
  public exclamation_icon: string;
  public iconClass: string;

  public ngOnInit(): void {
    const iconMap: Record<number, string> = {
      0: this.xmark_icon,
      1: this.checkmark_icon,
      2: this.loading_icon,
      3: this.parking_icon,
      4: this.exclamation_icon,
    };

    this.iconClass = iconMap[this.item?.[this.field]];
  }
}
