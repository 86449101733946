export enum Entity {
  user = 'user',
  role = 'role',
  address = 'address',
  fitonAnswer = 'fiton-answer',
  product = 'product',
  mainGroup = 'main-group',
  characteristic = 'characteristic',
  productGroup = 'product-group',
  supplier = 'supplier',
  customer = 'customer',
  contact = 'contact',
  costType = 'cost-type',
  cost = 'cost',
  status = 'status',
  rawMaterial = 'raw-material',
  dayPrice = 'day-price',
  country = 'country',
  language = 'language',
  currency = 'currency',
  document = 'document',
  purchaseOrder = 'purchase-order',
  priceList = 'price-list',
  salesOrder = 'sales-order',
  supplierPrice = 'supplier-product-price',
  templateRow = 'template-row',
  priceGroup = 'price-group',
  template = 'template',
  stock = 'stock',
  stockCount = 'stock-count',
  stockInLineEdit = 'stock-in-line-edit',
  negativeStock = 'negative-stock',
  customerProductPublic = 'customer-product-public',
  report = 'report',
  total = 'total',
  ediCodes = 'edi-codes',
  creditNote = 'credit-note',
  fitonQuestion = 'fiton-question',
  dieselSurcharge = 'diesel-surcharge',
  exchangeRate = 'exchange-rate',
  goodsReceive = 'goods-receive',
  goodsRelease = 'goods-release',
  transport = 'transport',
  order = 'order',
  ride = 'ride',
  driver = 'driver',
  palletLabel = 'pallet-label',
  orderpicking = 'orderpicking',
  transfer = 'transfer',
  location = 'location',
  locationInformation = 'location-information',
  dashboard = 'dashboard',
  spot = 'spot',
  palletReturn = 'pallet-return',
  payment = 'payment',
  stockMutation = 'stock-mutation',
  forecast = 'forecast',
  transportType = 'transport-type',
  warehouse = 'warehouse',
  dieselSurchargeCustomers = 'diesel-surcharge-customers',
  userActivity = 'user-activity',
}

export enum BuildersListKey {
  listCustomerProductPublic = 'list_customer-product-public',
  listCustomerProductPublicEdit = 'list_customer-product-public-edit',
  listTransportOrderProduct = 'list_transport-order-product',
  listSplitSaleOrder = 'list_sales-order-product-split',
}

export enum BuildersFormKey {
  formCustomerContactPublicConfirmation = 'form_customer-contact-public-confirmation',
  formSalesOrderProduct = 'form_sales-order-product',
  formEdiCodesDocuments = 'form_edi-codes-documents',
  formSalesOrderBulkPayment = 'form_sales-order-bulk-payment',
  formPurchaseOrderProductComment = 'form_purchase-order-product-comment',
  formWmsSpotProduct = 'form_wms-spot-product',
  formWmsSpotProductMove = 'form_wms-spot-product-move',
  formWmsAssignOrder = 'form_wms-assign-order',
  formWmsPickerLevels = 'form_wms-picker-levels',
  formWmsProductSplit = 'form_wms-product-split',
  formWmsProductReportChange = 'form_wms-product-report-change',
  formDisapproveQualityReason = 'form_disapprove-quality-reason',
  formChangeSalesOrderProductQuantity = 'form_change-sales-order-product-quantity',
}
