import { Component } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';
import { DatesFormats } from '@core/configs/date-config';

@Component({
  selector: 'app-list-renderer-widget-date',
  template: `
    <div>
      {{ item[field] | date : dateFormat : '+0100' }}
    </div>
  `,
})
export class ListRendererWidgetDateComponent extends ListRendererWidgetBase {
  public dateFormat = DatesFormats.isoDateFormat;
}
