import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { InputFocussed, UpdateChangedItem } from '@store/general/general.actions';
import { of, Subject } from 'rxjs';
import { catchError, filter, first, map, takeUntil } from 'rxjs/operators';
import { ChangedItem } from '@store/general/general-state.model';
import { UntypedFormControl } from '@angular/forms';
import { GeneralSelectors } from '@store/general/general.selectors';
import { InputMethodHandlingType } from '@core/models/input-method-handling-type.model';
import { InputWidgetService } from '@core/services/input-widget.service';
import { InterpolationPipe } from '@capturum/ui/api';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';
import { NgxPermissionsService } from 'ngx-permissions';
import { OpenSidebarService } from '@core/actions/open-sidebar.service';
import { PanelSelectors } from '@store/panel/panel.selectors';
import { Store } from '@ngxs/store';
import { TableUpdateType } from '@core/enums/table-type.enum';
import { ToastNotificationService } from '@shared/services/toast-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { UpdateItemInPanel } from '@store/panel/panel.actions';
import { cloneDeep } from 'lodash';
import { BreakPointService } from '@shared/services/breakpoint.service';

enum ValidationMessagePosition {
  left = 'left',
  right = 'right',
}

@Component({
  selector: 'app-input-widget-base',
  template: '',
})
export class InputWidgetBaseComponent extends ListRendererWidgetBase implements OnInit, OnDestroy {
  public endpoint: {
    method: InputMethodHandlingType;
    url: string;
    payload?: Record<string, string>;
  };

  // tslint:disable-next-line:variable-name
  public validation_message_position: ValidationMessagePosition;
  public focus: boolean;
  public control: UntypedFormControl = new UntypedFormControl();
  public decimals: number;
  public doNotUpdateControlAfterSave: boolean;
  public tableType: TableUpdateType;
  public ignoreInitialValueFromStore: boolean;
  public updateIfNoValueChange: boolean;
  public notSetInitialValue: boolean;
  public uniqueProperty = 'id';

  protected inputValueChange: Subject<number> = new Subject<number>();
  protected destroy$: Subject<boolean> = new Subject<boolean>();

  private changesInTheRow$ = this.inputWidgetService.changesInTheRow.asObservable();

  private permissions: string[];
  private response: any;

  constructor(
    public readonly cdr: ChangeDetectorRef,
    public readonly breakPointService: BreakPointService,
    protected readonly store: Store,
    protected readonly inputWidgetService: InputWidgetService,
    protected readonly openSidebarService: OpenSidebarService,
    protected readonly interpolationPipe: InterpolationPipe,
    protected readonly ngxPermission: NgxPermissionsService,
    protected readonly translateService: TranslateService,
    protected readonly toastService: ToastNotificationService,
    protected readonly ngZone: NgZone,
  ) {
    super();
  }

  protected get method(): string {
    return this.endpoint?.method?.toLocaleLowerCase();
  }

  private get currentItem(): ChangedItem {
    const listRendererData = this.store.selectSnapshot(GeneralSelectors.getSavedListRenderData);
    const updatedItem = listRendererData?.find((item) => {
      return item?.[this.uniqueProperty] === this.item?.[this.uniqueProperty];
    });
    const currentItem = updatedItem?.[this.uniqueProperty] === this.item?.[this.uniqueProperty];

    return {
      ...(currentItem ? updatedItem : this.item),
      [this.field]: this.control.value,
    };
  }

  public ngOnInit(): void {
    if (!this.checkPermission()) {
      this.control.disable();

      return;
    }

    this.listenToInputValueChanges();
    this.updateRowOnChanges();
    this.setInitialValue();
  }

  public ngOnDestroy(): void {
    this.inputWidgetService.changesInTheRow.next({
      rowIndex: null,
      changedFields: {},
    });

    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public savePayload(value: string | number): void {
    const url = this.interpolationPipe.transform(this.endpoint?.url, this.item);
    let payload: object = cloneDeep(this.endpoint?.payload ?? {});

    payload = Object.keys(payload).reduce((acc, key) => {
      return { ...acc, [key]: this.getPropertyValue(payload[key]) };
    }, {});

    const additionalPayload = this.store.selectSnapshot(GeneralSelectors.additionalPayload);

    if (additionalPayload) {
      payload = { ...additionalPayload, ...payload };
    }

    const body = { [this.field]: value, ...cloneDeep(payload) };

    this.inputWidgetService.submittingInputValueInProgress$.next(true);
    this.inputWidgetService.submittingRequestsQueue.push(this.item[this.uniqueProperty]);

    this.inputWidgetService
      .saveInputValue(url, body)
      .pipe(
        first(),
        map((response: any) => {
          return response?.data;
        }),
        catchError((error) => {
          this.handleInputError(error?.error?.data);
          this.cdr.detectChanges();

          return of(null);
        }),
      )
      .subscribe((response) => {
        this.response = response;

        const inputIndex = this.inputWidgetService.submittingRequestsQueue.indexOf(this.item[this.uniqueProperty]);

        this.inputWidgetService.submittingRequestsQueue.splice(inputIndex, 1);

        if (!this.inputWidgetService.submittingRequestsQueue.length) {
          this.inputWidgetService.submittingInputValueInProgress$.next(false);
        }

        if (!this.focus) {
          this.updateTableData();
        }
      });
  }

  public updateRowOnChanges(): void {
    this.changesInTheRow$
      .pipe(
        filter(({ rowIndex }) => {
          return this.index === rowIndex;
        }),
        filter(({ changedFields }) => {
          return (
            !!changedFields &&
            (!!changedFields[this.field] || changedFields[this.field] === 0 || changedFields[this.field] === null)
          );
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(({ changedFields, savePayload }) => {
        this.control.setValue(changedFields[this.field]);

        if (this.tableType === TableUpdateType.bulk) {
          this.store.dispatch(new UpdateChangedItem(this.field, this.index, this.currentItem));
        }

        if (
          this.store.selectSnapshot(GeneralSelectors.focusedFieldName) === this.field &&
          this.store.selectSnapshot(GeneralSelectors.focusedRowIndex) === this.index
        ) {
          this.selectTextOnFocus();
        }

        if (savePayload && this.endpoint?.url) {
          this.savePayload(changedFields[this.field]);
        }

        this.cdr.detectChanges();
      });
  }

  // will dispatch an event, and we can update the entire row if there are some dependencies
  public updateTableData(): void {
    if (!this.response) {
      return;
    }

    if (!this.doNotUpdateControlAfterSave) {
      this.control.setValue(this.response[this.field], { emitEvent: false });
    }

    const rowData = this.response;

    this.store.dispatch(new UpdateChangedItem(this.field, this.index, rowData));

    this.response = null;
    this.cdr.detectChanges();
  }

  public setFocus(focussed: boolean): void {
    this.focus = focussed;

    if (this.focus && this.openSidebarService.isSidebarOpen && this.openSidebarService.updateSidebarOnNextRowTab) {
      const currentPanelItem = this.store.selectSnapshot(PanelSelectors.getConfig);

      if (currentPanelItem && currentPanelItem?.options?.item[this.uniqueProperty] !== this.item[this.uniqueProperty]) {
        this.store.dispatch(new UpdateItemInPanel(this.item, this.openSidebarService.getItemName(this.item)));
      }
    }

    this.store.dispatch(new InputFocussed(focussed ? this.index : null, focussed ? this.field : null));
  }

  public listenToInputValueChanges(): void {
    this.inputValueChange
      .asObservable()
      .pipe(
        filter((newValue) => {
          return this.filterValueChanges(newValue);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((value) => {
        this.handleValueChange(value);
      });
  }

  public checkForValueChange(value: number | string): boolean {
    return (
      parseFloat(this.item[this.field] || 0) !== parseFloat(`${value || 0}`) ||
      // trigger value change on deleting a value from the input
      (value === null && parseFloat(this.item[this.field]) >= 0)
    );
  }

  protected selectTextOnFocus(): void {
    return;
  }

  protected handleValueChange(value: string | number): void {
    switch (this.method) {
      case InputMethodHandlingType.store:
        this.item = {
          ...this.item,
          [this.field]: value,
        };

        this.store.dispatch(new UpdateChangedItem(this.field, this.index, this.currentItem));
        break;
      case InputMethodHandlingType.post:
        this.savePayload(value);
        break;
      default:
        break;
    }
  }

  protected filterValueChanges(newValue: string | number): boolean {
    if (this.method === InputMethodHandlingType.store) {
      return true;
    } else if (!this.checkForValueChange(newValue)) {
      this.store.dispatch(new UpdateChangedItem(this.field, this.index));

      return false;
    }

    const previousItem = this.store
      .selectSnapshot(GeneralSelectors.getSavedListRenderData)
      ?.find((listRendererItem) => {
        return listRendererItem?.[this.uniqueProperty] === this.item?.[this.uniqueProperty];
      });
    const isValueEqual = previousItem
      ? previousItem[this.field] === newValue || parseFloat(previousItem[this.field]) === parseFloat(`${newValue}`)
      : false;

    return !isValueEqual;
  }

  protected handleInputError(responseErrorData: ChangedItem): void {
    this.control.setValue(responseErrorData?.[this.field] || this.item?.[this.field], { emitEvent: false });

    if (responseErrorData) {
      this.store.dispatch(new UpdateChangedItem(this.field, this.index, responseErrorData));
    }
  }

  protected setInitialValue(): void {
    if (this.notSetInitialValue) {
      return;
    }

    const storeItem = this.store.selectSnapshot(GeneralSelectors.getSavedListRenderData)?.find((row) => {
      return row?.[this.uniqueProperty] === this.item?.[this.uniqueProperty];
    });
    const value = this.item?.[this.field] ?? (this.ignoreInitialValueFromStore ? null : storeItem?.[this.field]);

    if (value || value === 0) {
      if (this.field === 'submit_stock') {
        const valueToSet = storeItem?.[this.field] ?? this.item[this.field];

        this.control.setValue(valueToSet);
      } else {
        this.control.setValue(value);
      }

      this.cdr.detectChanges();

      if (this.field === 'count') {
        this.inputWidgetService.changesInTheRow.next({
          [this.uniqueProperty]: this.item[this.uniqueProperty],
          changedFields: {
            count: value,
          },
        });
      }

      // To trigger ChangedItem store action, and afterwards, update stored list renderer items
      if (this.method === InputMethodHandlingType.store) {
        this.inputValueChange.next(this.control.value);
      }

      this.cdr.detectChanges();
    }
  }

  private checkPermission(): boolean {
    if (this.permissions?.length) {
      return this.permissions.every((permission) => {
        return this.ngxPermission.getPermission(permission);
      });
    }

    return true;
  }

  private getPropertyValue(key: string): string {
    let propertyValue: string;
    const text = `{{${key}}}`;

    propertyValue = this.interpolationPipe.transform(text, cloneDeep(this.item));

    if (!propertyValue) {
      propertyValue = this.interpolationPipe.transform(text, cloneDeep(this.currentItem));
    }

    return propertyValue;
  }
}
